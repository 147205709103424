import { AnalyticsBrowser } from "@segment/analytics-next";

export const analytics =
  process.env.NODE_ENV === "production"
    ? AnalyticsBrowser.load(
        {
          writeKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY!,
          cdnURL: `https://a.appsmith.com`,
        },
        {
          integrations: {
            "Segment.io": {
              apiHost: "t.appsmith.com/v1",
              protocol: "https",
            },
          },
        },
      )
    : null;
