'use client';

import NextLink from 'next/link';
import React, { ReactNode } from 'react';
import parseUrl from 'utils/parseUrl';
import { getSectionIdForLinkOrButton } from 'utils/functions';
import { analytics } from "lib/segment";

interface LinkProps {
  href: string;
  children: ReactNode;
  passHref?: boolean;
  className?: string;
  asChild?: boolean;
  target?: string;
  rel?: string;
  download?: string;
}

const Hyperlink = React.forwardRef(function Link({ children, href, className, passHref, ...rest }: LinkProps, _ref) {
  let link_type = href && (href[0] === '/' || href.includes('appsmith.com')) ? 'internal' : 'outbound';
  const { isInternal, href: url } = parseUrl(href);

  if (isInternal && url.includes('#')) {
    return (
      <a className={className} href={href || ''}>
        {children}
      </a>
    );
  }

  return (
    <NextLink
      onClick={e => {
        analytics?.track('Clicked Link', {
          link_url: href,
          page_section: getSectionIdForLinkOrButton(e.currentTarget as HTMLAnchorElement),
          link_type: link_type,
        });
      }}
      className={className}
      href={url || ''}
      passHref={passHref}
      target={isInternal ? undefined : '_blank'}
      rel={isInternal ? undefined : 'noopener noreferrer'}
      {...rest}
    >
      {children}
    </NextLink>
  );
});

export default Hyperlink;
