import { useRouter } from 'next/router';

const parseUrl = (href: string) => {
  if (!href) {
    return {
      as: 'div' as unknown as undefined,
    } as const;
  }

  const domain = 'appsmith.com';
  if (href[0] === '/') {
    href = `https://www.appsmith.com${href}`;
  }

  if (href[0] === '#') {
    return {
      isInternal: true,
      href,
    };
  }

  let url = href as unknown as URL;

  try {
    url = new URL(href);
  } catch (error) {
    console.error('Invalid link', href);
  }

  const isInternal = url.hostname === `www.${domain}` || url.hostname === domain;

  return {
    as: 'a' as unknown as undefined,
    rel: isInternal ? '' : 'noreferrer noopener',
    target: isInternal ? '' : '_blank',
    href: isInternal ? url.href.split(url.host)[1] : href,
    isInternal,
  };
};

export const parseVimeo = (url: string) => {
  const vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
  const parsed = url.match(vimeoRegex);
  if (parsed) {
    return 'https://player.vimeo.com/video/' + parsed[1];
  } else {
    return false;
  }
};

/**
 * @param url any URL string
 * @returns youtube ID
 */
export const getYoutubeId = (url: string) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url?.match(regExp);

  return match && match[7].length === 11 ? match[7] : false;
};

export default parseUrl;
